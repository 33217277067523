/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react';
import dynamic from 'next/dynamic';
import DecaButton from '@/src/components/DecathlonTheme/DecaButton';
import { TabletMobile, Desktop } from '../../common/CustomMediaQuery';
const BannerStyles = dynamic(() => import('./Mainbanner.module.scss'), {
  ssr: false,
});
import DecaLink from '../../DecathlonTheme/DecaLink';
import Image from 'next/image';

const BannerV1 = ({ banner, i, GAEvent_mainBannerClick, maindata }) => {
  return (
    <div className={BannerStyles.mainBanner} key={i}>
      <DecaLink
        href={banner.url}
        onClick={() => GAEvent_mainBannerClick(maindata)}
        target={banner.openInNewTab ? '_blank' : ''}
        pageType={banner.pageType}
      >
        <Desktop>
          <Image
            src={banner.tabletImage}
            className="w-full"
            alt="BannerV1"
            width={1920}
            height={470}
            sizes="(max-width: 1024px) 1024px, (max-width: 1200px) 1200px, (max-width: 1440px) 1440px, (max-width: 1680px) 1680px,(max-width: 1800px) 1800px,(max-width: 1920px) 1920px"
          />
        </Desktop>
        <TabletMobile>
          <Image
            src={banner.mobileImage}
            className="w-full"
            alt="BannerV1"
            width={768}
            height={502}
            loading="eager"
            sizes="(max-width: 320px) 320px, (max-width: 425px) 425px, (max-width: 768px) 768px, (max-width: 1024px) 1024px "
          />
        </TabletMobile>
        {banner?.ctaText && (
          <div className="absolute w-full text-center bottom-8 uppercase italic">
            <h2 className="text-40 font-bold ">{banner?.bigTitle}</h2>
            <h2 className="text-20 not-italic">{banner?.smallTitle}</h2>
            <p>{banner?.text}</p>
            <DecaButton
              color="brand-blue"
              className="max-w-[200px] text-12 md:text-16 mt-4 uppercase caret-transparent  bg-blue-400 py-2 px-4 text-white"
            >
              {banner?.ctaText}
            </DecaButton>
          </div>
        )}
      </DecaLink>
    </div>
  );
};
export default BannerV1;
