import React from 'react';

const BlogBannerSkeleton = () => {
  return (
    <div className="mt-8 mb-4">
      <div>
        <div className="lg:flex lg:justify-between lg:items-center">
          <div className="lg:flex">
            <div className="h-6 skeleton dark md:h-12" style={{ aspectRatio: '41 / 8' }}></div>
            <p className="mt-1 font-semibold uppercase lg:ml-10 text-14 md:text-22 lg:text-28 xl:text-32 text-grey-700">
              <span className="skeleton dark">
                <span className="invisible">READ OUR SPORTY STORIES</span>
              </span>
            </p>
          </div>
          <div className="hidden lg:block">
            <span className="px-4 py-3 font-semibold tracking-wider bg-blue-100 skeleton">
              <span className="skeleton dark">
                <span className="invisible">View All</span>
              </span>
            </span>
          </div>
        </div>
        <div className="flex flex-wrap mx-2 mt-4 -my-2">
          {Array.from(Array(4)).map((item, index) => (
            <div className="w-1/2 px-2 my-2 lg:w-1/4" key={index}>
              <div>
                <div className="overflow-hidden rounded-t-md">
                  <span
                    style={{
                      boxSizing: 'border-box',
                      display: 'block',
                      overflow: 'hidden',
                      width: 'initial',
                      height: 'initial',
                      background: 'none',
                      opacity: 1,
                      border: '0px',
                      margin: '0px',
                      padding: '0px',
                      position: 'relative',
                    }}
                  >
                    <div className="w-full skeleton dark" style={{ aspectRatio: '164 / 89' }} />
                  </span>
                  <div className="px-3 py-2 bg-grey-50">
                    <h2 className="font-semibold leading-tight text-12 md:text-16 xl:text-18 text-grey-700">
                      <span className="skeleton dark">
                        <span className="invisible">10 Best Winter Jackets For Men - Buyer's Guide</span>
                      </span>
                    </h2>
                    <div className="flex items-center justify-between mt-3 xl:mt-6">
                      <p className="text-12 md:text-14 xl:text-16 text-grey-700">
                        <span className="skeleton dark">
                          <span className="invisible">(7 min Read)</span>
                        </span>
                      </p>
                      <p className="font-semibold text-blue-400 text-12 md:text-14 xl:text-20">
                        <span className="skeleton dark">
                          <span className="invisible">Read More</span>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="block py-1 mt-10 text-center text-blue-500 rounded skeleton lg:hidden text-14">
          <span className="skeleton dark">
            <span className="invisible">View All</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BlogBannerSkeleton;
