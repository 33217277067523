/* eslint-disable no-script-url */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import PropTypes from 'prop-types';
import { withDeviceContext } from '../../../context/DeviceContext';

const DecaLink = ({ children, href, target, pageType, className, onClick, contextDevice, dataTestId }) => {
  if (!children) return null;

  const relativeURL = (href || 'javascript:').replace(/((http|https):|)(^|\/\/)(.*?\/)/, '/');
  let linkData = {};

  if (relativeURL.indexOf('/shop/') !== -1 || pageType === 'page' || pageType === 'shop') {
    linkData = {
      href: {
        pathname: '/shop/[...shop]',
        query: {
          shop: [relativeURL.replace('/shop/', '')],
        },
      },
      as: relativeURL,
      pageType: pageType || 'default_shop',
    };
  } else if (relativeURL.indexOf('/p/') !== -1 || pageType === 'p') {
    const modelId = relativeURL.split('/', 3)[2];
    linkData = {
      href: {
        pathname: '/p/[...product]',
        query: {
          product: relativeURL.slice(1).split('/'),
          id: modelId,
          type: 'p',
        },
      },
      pageType: pageType || 'default_p',
      as: relativeURL,
    };
  } else if (pageType === 'c') {
    const categoryId = relativeURL
      .substring(relativeURL.lastIndexOf('-') + 1, relativeURL.length)
      .replace(/[^0-9]/g, '');
    linkData = {
      href: {
        pathname: '/[...category]',
        query: {
          category: relativeURL.slice(1).split('/'),
          id: categoryId,
          type: 'c',
        },
      },
      pageType: pageType || 'default_c',
      as: relativeURL,
    };
  }

  if (linkData.href) {
    return (
      // <Link href={linkData.href} prefetch={false} as={linkData.as} legacyBehavior>
      <a
        href={linkData.as || relativeURL}
        target={contextDevice === 'desktop' ? target : '_self'}
        className={className}
        onClick={(event) => onClick(event)}
        data-page-type={linkData.pageType}
        data-test-id={dataTestId}
      >
        {children}
      </a>
      // </Link>
    );
  }

  return (
    <a
      href={href || 'javascript:'}
      target={target}
      className={className}
      onClick={(event) => onClick(event)}
      data-page-type="default"
      data-test-id={dataTestId}
    >
      {children}
    </a>
  );
};

DecaLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.func)]),
  href: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  pageType: PropTypes.string,
  onClick: PropTypes.func,
};

DecaLink.defaultProps = {
  className: '',
  pageType: '',
  href: 'javascript:',
  target: '_self',
  onClick: () => {},
};
export default withDeviceContext(DecaLink);
